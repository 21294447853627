import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faEye, faTrashAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { SearchResultsContext } from './SearchResultsContext';
import { useCart } from './CartContext';
import { useUI } from './UIContext';
import { API_URL } from '../../config';

const SearchResultsDrawer = () => {
  const { searchResults } = useContext(SearchResultsContext);
  const { toggleBackdrop, showContent } = useUI();
  const navigate = useNavigate();
  const { cart, addToCart, removeFromCart } = useCart();

  const formatCurrency = value => `$${parseFloat(value).toFixed(2)}`;

  const handleAddAllToCart = async (accountNumber) => {
    try {
      const response = await fetch(`${API_URL}/property/${accountNumber}/bills`);
      const bills = await response.json();
      if (response.ok) {
        bills.forEach(bill => {
          if (!cart.some(item => item.id === bill.id) && !bill.is_delinquent) {
            addToCart(bill);
          }
        });
      } else {
        console.error('Error fetching bills:', bills.message);
        alert('Failed to fetch bills for this account.');
      }
    } catch (err) {
      console.error('Error:', err);
      alert('An error occurred while adding bills to the cart.');
    }
  };

  const isItemInCart = (itemId) => cart.some(item => item.id === itemId);

  return (
    showContent && (
      <div>
        <div className="content-div content-popup">
          <button className="close-link" onClick={() => toggleBackdrop(false)} style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
          <div className="white-wrapper p-30 mt-30" style={{ height: 'auto', overflow: 'scroll' }}>
            <h2 className="mt-0 pb-10">Accounts</h2>
            <table className="table table-striped-columns">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Owner</th>
                  <th>Address</th>
                  <th className="text-right">Due</th>
                  <th width="10%" className="text-right">Action</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result) => (
                  <tr key={result.id}>
                    <td>{result.account_number}</td>
                    <td>{result.owner_name}</td>
                    <td>{result.property_address}</td>
                    <td className="text-right">
                      {parseFloat(result.total_balance) > 0 ? (
                        <span className="pending-bill">
                          {formatCurrency(result.total_balance)}
                          {result.is_delinquent && (
                            <span style={{ color: 'red', marginLeft: '10px' }}>Delinquent</span>
                          )}
                        </span>
                      ) : (
                        <span className="already-paid">All Paid</span>
                      )}
                    </td>
                    <td className="text-right">
                      {isItemInCart(result.id) ? (
                        <button onClick={() => removeFromCart(result.id)}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      ) : (
                        <button
                          onClick={() => handleAddAllToCart(result.account_number)}
                          disabled={result.is_delinquent}
                          style={result.is_delinquent ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                        >
                          <FontAwesomeIcon icon={faCartPlus} />
                        </button>
                      )}
                      <button
                        onClick={() => {
                          navigate(`/property-detail/${result.id}`);
                          toggleBackdrop(false);
                        }}
                        disabled={result.is_delinquent}
                        style={result.is_delinquent ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
                      >
                        <FontAwesomeIcon icon={faEye} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="dull-box"></div>
      </div>  
    )
  );
};

export default SearchResultsDrawer;
