import React, { useState, useEffect } from 'react';
import LoginPopup from './LoginPopup';
import { useAuth } from './AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { useCart } from './CartContext';


const imageUrlLogoW = process.env.PUBLIC_URL + '/images/rmt-logo-white.png';
const imageUrlLogo = process.env.PUBLIC_URL + '/images/rmt-logo.png';


const LoggedInMenu = ({ userName, userEmail, handleLogout }) => {

    const { itemCount, totalDue } = useCart();

    return (
        <div className="loggedin-bg">
            <div><small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small></div>
            <div className="container-fluid">
                <div className="header inner-header">
                    <div className="logo">
                        <Link to="/account">
                            <img src={imageUrlLogoW} alt="RMT" />
                        </Link>
                    </div>
                    <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                        <span className="sr-only">Toggle navigation</span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </button>
                    <div className="collapse navbar-collapse menu-links" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <li role="presentation">
                                <Link to="/account">
                                    <span className="glyphicon glyphicon-stats"></span> Dashboard
                                </Link>
                            </li>
                            <li role="presentation" className="dropdown">
                                <Link
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span className="glyphicon glyphicon-home"></span> Services
                                    <span className="caret"></span>
                                </Link>
                                <ul className="dropdown-menu">

                                    <li>
                                        <Link to="/account">
                                            <span className="glyphicon glyphicon-credit-card"></span> License Plate Information
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/account" className="">
                                            <span className="glyphicon glyphicon-home"></span> Property Tax Information
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="coming-soon">
                                            <span className="glyphicon glyphicon-credit-card"></span> Drivers License Information
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="coming-soon">
                                            <span className="glyphicon glyphicon-list-alt"></span> Permits & License Information
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="coming-soon">
                                            <span className="glyphicon glyphicon-wrench"></span> Utility Information
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="#" className="coming-soon">
                                            <span className="glyphicon glyphicon-briefcase"></span> Business Tax Information
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li role="presentation" className="dropdown">
                                <Link
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span className="glyphicon glyphicon-cog"></span> Account Management
                                    <span className="caret"></span>
                                </Link>
                                <ul className="dropdown-menu">
                                    <li>
                                        <Link to="/payment-methods">
                                            <span className="glyphicon glyphicon-credit-card"></span> Payment Methods
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/profile">
                                            <span className="glyphicon glyphicon-book"></span> Saved Contacts
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>


                    <div className="login-button">
                        {localStorage.getItem('adminAsCustomer') === 'yes' && <ul className="nav navbar-text pull-right" style={{ 'backgroundColor': 'orange', 'padding': '10px' }} >
                            <li role="presentation" ><span >Log as Admin</span></li>
                        </ul>}

                        <ul className="nav nav-pills pull-right">

                            <li role="presentation" className="dropdown">
                                <Link
                                    className="dropdown-toggle"
                                    data-toggle="dropdown"
                                    role="button"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span className="glyphicon glyphicon-user"></span>
                                    <span className="caret"></span>
                                </Link>

                                <ul className="dropdown-menu user-menu">

                                    <li>
                                        <div className="user-profile">
                                            <div className="user-info">
                                                <span className="user-name">{userName}</span>
                                                <span className="user-email">{userEmail}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="/profile">
                                            <span className="glyphicon glyphicon-user"></span> Your Profile
                                        </Link>
                                    </li>
                                    <li>
                                        <Link onClick={handleLogout}>
                                            <span className="glyphicon glyphicon-log-out"></span> Logout
                                        </Link>
                                    </li>
                                </ul>

                            </li>
                        </ul>

                        <div className="cart-link pt-20">   
                            <Link to="/">
                                Search <span className="glyphicon glyphicon-search"></span>
                            </Link>                 
                            <Link to="/cart">
                                ${totalDue()}
                                <span className="glyphicon glyphicon-shopping-cart"></span>
                                <span className="badge">{itemCount()}</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const LoggedOutMenu = ({ handleLoginClick }) => {
    const { itemCount, totalDue } = useCart();
    return (
        <div className="container-fluid">
            <div className="header inner-header">
                <div className="logo">
                    <Link to="/">
                        <img src={imageUrlLogo} alt="RMT" />
                    </Link>
                </div>
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                </button>
                <div id="bs-example-navbar-collapse-1" className="menu-links">
                    <div className="navbar-collapse front-color pull-left mobile-nav">
                        <ul className="nav navbar-nav pull-right">
                            <li role="presentation">
                                <Link to="/property/how/works">
                                    How it works
                                </Link>
                            </li>
                            <li role="presentation">
                                <Link to="/property/pricing">
                                    Pricing
                                </Link>
                            </li>
                            <li role='presentation'>
                                <Link to='https://renewmy.tech' target='_blank'>
                                    About
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="login-button pull-right cart-link pt-20">
                        <Link onClick={() => handleLoginClick("/account")}>
                            Sign in <span className="glyphicon glyphicon-user"></span>
                        </Link>

                        <Link to="/">
                            Search <span className="glyphicon glyphicon-search"></span>
                        </Link>
                        <Link to="/cart">
                            ${totalDue()}
                            <span className="glyphicon glyphicon-shopping-cart"></span>
                            <span className="badge">{itemCount()}</span>
                        </Link>
                       
                    </div>

                </div>
            </div>
        </div>
    );
};

const Header = () => {
    const [isLoginOpen, setLoginOpen] = useState(false);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [onSuccessRedirect, setOnSuccessRedirect] = useState("/account");
   
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setLoggedIn(true);
            const user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                setUserName(user.name);
                setUserEmail(user.email);
            }
        }
    }, []);
    const handleLoginClick = (redirectPath) => {
        setOnSuccessRedirect(redirectPath || "/account");
        setLoginOpen(true);
    };

    const closeLoginPopup = () => {
        setLoginOpen(false);
    };

    const handleLogout = () => {
        logout();
        setLoggedIn(false);
        setUserName('');
        navigate('/');
    };

    return (
        <>
            {isLoggedIn ? (
                <LoggedInMenu userName={userName} userEmail={userEmail} handleLogout={handleLogout} />
            ) : (
                <LoggedOutMenu handleLoginClick={handleLoginClick} />
            )}
            <LoginPopup isOpen={isLoginOpen} onRequestClose={closeLoginPopup} onSuccessRedirect={onSuccessRedirect} />
        </>
    );
};

export default Header;
