import React, { useState, useEffect } from "react";

import HeaderProperty from '../component/HeaderProperty';
import Footer from '../component/Footer';

const HowItWorksProperty = () => {
     const [isScrolled, setIsScrolled] = useState(false); // State to track whether header is scrolled

    // Function to handle scrolling
    const handleScroll = () => {
        // Detect if user has scrolled beyond a certain threshold
        if (window.scrollY > 50) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        // Add event listener for scroll
        window.addEventListener('scroll', handleScroll);
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Only run this effect once on component mount
    

    return (
        <div>
            <div className={`property-detail-head${isScrolled ? ' scrolled' : ''}`}>
                <HeaderProperty />
            </div>
            <div className="static-page-wrap">
                <div className="wrap-img"></div>
                <div className="inner-wrapper">
                    <div className="container">
                        <div className="row pb-20">
                            <div className="col-md-2 col-xs-12"></div>
                            <div className="col-md-8 col-xs-12">
                                <div className="page-head">
                                    <h1>How Renew My Taxes Works</h1>
                                    <p>
                                        Welcome to Renew My Taxes! Discover how our platform simplifies the process of managing your property tax payments. With our user-friendly features, you can automate the payment process, ensuring that your property taxes are always up to date.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-2 col-xs-12"></div>
                        </div>
                        
                        <div className="white-wrapper">
                            <h4>1. Property Information</h4>
                            <p>
                                Start by providing your property's details, including the account number and other relevant information. This step helps us identify your property and its tax requirements.
                            </p>

                            <h4>2. Select Your Municipality and State</h4>
                            <p>
                                Choose your municipality and state from our extensive list of options. Our app covers a wide range of locations, ensuring that you can always find your specific municipality for accurate tax processing.
                            </p>

                            <h4>3. Secure Payment Setup</h4>
                            <p>
                                Add your payment information to your account securely. We use encryption and robust security measures to protect your sensitive data. Rest assured that your payment details are safe and protected.
                            </p>

                            <h4>4. Automatic Payment Activation</h4>
                            <p>
                                Enable our automatic payment feature to ensure your property taxes are paid automatically each year. Say goodbye to the hassle of remembering due dates or dealing with late fees.
                            </p>

                            <h4>5. Receive Timely Notifications</h4>
                            <p>
                                We'll send you notifications and reminders as your payment date approaches. Stay informed about the payment process, review your payment, and make any necessary updates with ease.
                            </p>

                            <h4>6. Payment Confirmation</h4>
                            <p>
                                After successful payment processing, you'll receive a confirmation of your property tax payment. You can access your payment details within the app and obtain a digital copy for your records.
                            </p>

                            <h4>7. Enjoy Peace of Mind</h4>
                            <p>
                                With Renew My Taxes, managing your property taxes is simple and stress-free. Say goodbye to manual payments and hello to a smarter, more efficient way of keeping your taxes up to date.
                            </p>

                            <h4>8. Customer Support</h4>
                            <p>
                                Our dedicated customer support team is ready to assist you. Whether you have questions or need help, feel free to reach out through our in-app chat or email support.
                            </p>

                            <h4>9. Get Started Today</h4>
                            <p>
                                Join our community of satisfied users and let Renew My Taxes take care of your property tax needs. Experience the future of hassle-free tax payment. Sign up today and enjoy peace of mind.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default HowItWorksProperty;
